<template>

    <v-data-table
        :headers="headers"
        :items="templates"
        @dblclick:row="editItem"
        :search="search"
        :items-per-page="templates.length"

    >
      <template #top>
        <v-toolbar
            flat
            color="white"
        >
          <v-text-field
              v-model="search"
              :label="`Search in templates`"
          ></v-text-field>
          <v-spacer/>
          <v-dialog
              v-model="dialog"
              max-width="500px"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
              >
                New Item
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <v-text-field
                    dense
                    label="Classification"
                    v-model="editedItem.classification"
                />
                <v-spacer/>
                <v-switch
                    label="email"
                  v-model="editedItem.email"
                  />
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-textarea
                      v-model="editedItem.text"
                      rows="1"
                      auto-grow
                      label="Template Text"
                  />

                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer/>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="save(editedItem)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template #[`item.actions`]="{ item }">

        <v-icon
            @click="deleteItem(item)"
            color="red"
        >
          mdi-delete
        </v-icon>

      </template>
      <template #[`item.active`]="{ item }">
        <v-switch
            :color="item.active?'success':'secondary'"
            v-model="item.active"
            hide-details
            @click="save(item)"
        ></v-switch>
      </template>
      <template #[`item.email`]="{ item }">
        <v-switch
            :color="item.email?'success':'secondary'"
            v-model="item.email"
            hide-details
            @click="save(item)"
        ></v-switch>
      </template>


      <template #[`item.daysOffset`]="{ item }">
        <v-text-field v-if="editedItemId===item.id"
                      v-model="item.daysOffset"
                      :rules="integerRules"
                      required
                      @keydown.enter="save(item)"

        />
        <div v-else>{{item.daysOffset}}</div>

      </template>
      <!--      <template #[`item.text`]="{ item }">-->
      <!--        <v-textarea  v-if="editedItemId===item.id"-->
      <!--                     auto-grow-->
      <!--                     v-model="item.text"-->
      <!--        >-->
      <!--          <div-->
      <!--              slot="append">-->
      <!--            <v-btn-->
      <!--                small-->
      <!--                @click="save(item)">-->
      <!--              <v-icon color="green">-->
      <!--                mdi-content-save-->
      <!--              </v-icon>-->
      <!--            </v-btn>-->
      <!--            <v-btn-->
      <!--                small-->
      <!--                @click="cancel">-->
      <!--              <v-icon color="red">-->
      <!--                mdi-cancel-->
      <!--              </v-icon>-->
      <!--            </v-btn>-->
      <!--          </div>-->



      <!--        </v-textarea>-->

      <!--        <div v-else>{{item.text}}</div>-->
      <!--      </template>-->

    </v-data-table>


</template>

<script>
import {mapState} from "vuex"

export default {
  data() {
    return {
      search: '',
      editedItemId: -1,
      integerRules: [
        (value) => !!value || "Required.",
        (value) => {
          const pattern = /^[0-9]+$/;
          return pattern.test(value) || "Invalid number.";
        },
      ],
      dialog: false,
      editedItem: {},

      max255chars: (v) => v.length <= 255 || "Input too long!",
      pagination: {},
      headers: [
        {
          text: "Text",
          align: "start",
          value: "text",
        },
        {text: "Active", value: "active", width: "50px"},
        {text: "# Used", value: "timesUsed"},
        {text: "Email", value: "email"},
        {text: "Actions", value: "actions", sortable: false, width: "100"},
      ],
      defaultItem: {
        text: "",
        timesUsed: 0,

      },
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    ...mapState("templates", ['templates'])
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    save(item) {
      console.log("Saving template ", item);
      this.$store.dispatch("templates/updateTemplate", item);
      this.editedItemId=-1;
      this.close();
    },
    editItem (event, {item}) {
      this.editedItemId = item.id;
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      confirm("Are you sure you want to delete this item?") &&
      this.$store.dispatch("templates/deleteTemplate", item.id);
      this.close();
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItemId = -1;
      this.close();
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    close() {
      console.log("Dialog closed");
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItemId = -1;
      });
    },
  },
};
</script>

<style></style>
